import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'AARK-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xa7734065B4c76D810dc9584Ed2BE5ed605B42Df7',
    },
    tokenSymbol: 'AARK',
    tokenAddresses: {
      97: '',
      56: '0x27B0327BEeDd0531b7392F5ae0563D4F0A442C71',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'AARK-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x7610F81635E4410E081066919E2B502DC314a863',
    },
    tokenSymbol: 'AARK',
    tokenAddresses: {
      97: '',
      56: '0x27B0327BEeDd0531b7392F5ae0563D4F0A442C71',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'AARK',
    lpAddresses: {
      97: '',
      56: '0x7610F81635E4410E081066919E2B502DC314a863', // msc - busd lp
    },
    tokenSymbol: 'AARK',
    tokenAddresses: {
      97: '',
      56: '0x27B0327BEeDd0531b7392F5ae0563D4F0A442C71',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  }
]

export default farms
