import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   tokenName: 'CAKE',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://pancakeswap.finance/',
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  {
    sousId: 882,
    tokenName: 'BTT(OLD)',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x26e74ed7a509008B988608DFCc516B300A99E652',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bittorrent.com/',
    harvest: true,
    tokenPerBlock: '1.157407407407407407',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "0%"
  },
  {
    sousId: 883,
    tokenName: 'TRX',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x6123A8A0ad49e17aA0F053F61E6E1A8465ab5584',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://tron.network/',
    harvest: true,
    tokenPerBlock: '0.011557870370370370',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 884,
    tokenName: 'ADA(OLD)',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xdc919F870C9dA37E345DE1FD295b7D070D756564',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://cardano.org/',
    harvest: true,
    tokenPerBlock: '4.629629629629629629',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 885,
    tokenName: 'ADA',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x6Fd86139c78954c406ac1c0e3ffB45B26f74cdD1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://cardano.org/',
    harvest: true,
    tokenPerBlock: '0.0006944444444444445',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "0%"
  },
  {
    sousId: 886,
    tokenName: 'MOBOX',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x0712cB01B8a3E949e1dC777Dda8e870B2b3958Dd',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mobox.io',
    harvest: true,
    tokenPerBlock: '0.000238425925925925',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 887,
    tokenName: 'MATIC',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x957F2c11b5d303d42bbC58Aa1C2289fA50F01B71',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://polygon.technology/',
    harvest: true,
    tokenPerBlock: '0.000925925925925926',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 888,
    tokenName: 'BTT(OLD 2)',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x14599573662f1f6a30F37AFFC7404D1cE8Eb455B',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bittorrent.com/',
    harvest: true,
    tokenPerBlock: '0.30092592592592593',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 889,
    tokenName: 'DOGE',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x257d406dab5BAd82CD4aA2a30BeA04C9F35598C0',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.dogecoin.com',
    harvest: true,
    tokenPerBlock: '0.028680555555555556',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 8,
    fee: "0%"
  },
  {
    sousId: 890,
    tokenName: 'COTI',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xf3d39EAa9722458e740CC7957E421Ac1478a1685',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://coti.io/',
    harvest: true,
    tokenPerBlock: '0.002314814814814814',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 891,
    tokenName: 'BTT',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x0aF272cba8C1bd7bf3C49222737257E07ecAC8Df',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bittorrent.com/',
    harvest: true,
    tokenPerBlock: '0.196759259259259270',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 892,
    tokenName: 'TRX',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x6d78e18Ca8688Ef6e39ADE6CcaE8f76937CF0453',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://tron.network/',
    harvest: true,
    tokenPerBlock: '0.009259259259259259',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "2%"
  },
  {
    sousId: 893,
    tokenName: 'XRP',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x1bf94fd979b7a08242ca6ab61dee9c396213acf3',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://ripple.com/xrp/',
    harvest: true,
    tokenPerBlock: '0.000694444444444444',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 894,
    tokenName: 'ADA',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xcBE46ec125abd2ba99Af9975dFDE8BBc72803925',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://cardano.org/',
    harvest: true,
    tokenPerBlock: '0.000462962962962963',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 895,
    tokenName: 'SHIBA',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xd00C5Ca6087a6244b1645Adf3d4391767eCc23f3',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://shibatoken.com/',
    harvest: true,
    tokenPerBlock: '12.576851851851853',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 896,
    tokenName: 'BTT',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x425136e25007Be8C4fc44741cC994d0120060307',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bittorrent.com/',
    harvest: true,
    tokenPerBlock: '0.23152546296296297',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 897,
    tokenName: 'WPOOL',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xa7555EaB35c492E97217b61bee55EE2da5F3b794',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '0.13888078703703705',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 898,
    tokenName: 'COTI',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0xd96CBFea2A3f8a8E579519F7EBbEa2F09Af74297',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://coti.io/',
    harvest: true,
    tokenPerBlock: '0.001388888888888889',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 899,
    tokenName: 'RACA',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x02d2ef38431F562251f5212bf632Ac86ece0B91C',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.radiocaca.com/#/home',
    harvest: true,
    tokenPerBlock: '0.08101851851851852',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "1%"
  },
  {
    sousId: 900,
    tokenName: 'BCOIN',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x80b2c4D86bA9CF511a09b7AADfc4C49a668501F7',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://bombcrypto.io/',
    harvest: true,
    tokenPerBlock: '0.00006944444444444444',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "0%"
  },
  {
    sousId: 901,
    tokenName: 'HERO',
    stakingTokenName: QuoteToken.POOL,
    stakingTokenAddress: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    contractAddress: {
      97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      56: '0x8a71123bcbdee5eb44cd4a9746c2b1ba311a73a3',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://bombcrypto.io/',
    harvest: true,
    tokenPerBlock: '0.001736111111111111',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    fee: "0%"
  },
]

export default pools
