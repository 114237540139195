export default {
  cake: {
    56: '0x27B0327BEeDd0531b7392F5ae0563D4F0A442C71',
    97: '',
  },
  masterChef: {
    56: '0x7dE9E7DdDc9343389F54ceB445680291C32a976d',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0xB1fE7068bF3A06E4533a203D0b1b86a99F1C091D',
    97: '',
  },
  lotteryNFT: {
    56: '0x5b0E7B3E551E33475D25A289F42d0497cAAD1e4e',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  referral: {
    56: '0xe00130FfC400A2F7730c96f84180D8F3B9c1E5b1',
    97: '',
  }
}
